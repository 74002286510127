<template>
  <v-snackbar
    v-model="snackbarState"
    color="success"
    outlined
    :timeout="1500"
  >
    {{ successMessage }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    successMessage: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  computed: {
    snackbarState: {
      get () {
        return !!this.successMessage
      },
      set (val) {
        return val
      }
    }
  }
}
</script>
