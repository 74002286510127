<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails du slide : {{ slide ? slide.title : '-' }}
    </v-card-title>

    <div class="clear"></div>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h4>Description du slide</h4>
                    <br>
                    <v-text-field
                      v-model="entityData.title"
                      label="Titre"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Titre"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-model="entityData.url"
                      label="Url"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Url"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-model="entityData.txtZone1"
                      label="Texte zone 1"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Texte zone 1"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-model="entityData.txtZone2"
                      label="Texte zone 2"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Texte zone 2"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-model="entityData.txtZone3"
                      label="Texte zone 3"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Texte zone 3"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-model="entityData.position"
                      label="Position"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Position"
                      hide-details
                    ></v-text-field>

                    <v-switch
                      v-model="entityData.enabled"
                      :label="'Etat : ' + (entityData.enabled === true ? 'Activé' : 'Désactivé')"
                    ></v-switch>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo principale"
                      image-name="mainImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <br>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo mobile"
                      image-name="mobileImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'slideList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import ImageHandler from '@/components/Common/ImageHandler'

export default {
  components: {
    ImageHandler,
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      iconName: null,
      slide: null,
      entityData: {
        enabled: true,
        position: null,
        locale: 'fr',
        title: null,
        url: null,
        txtZone1: null,
        txtZone2: null,
        txtZone3: null,
        mainImage: {
          id: null,
          title: null
        },
        mobileImage: {
          id: null,
          title: null
        }
      }
    }
  },
  created () {
    this.getSlide()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putSlide()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getSlide () {
      const slideId = this.$route.params.slideId

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/slides/' + slideId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.slide = response.data
            this.entityData.id = this.slide.id
            this.entityData.enabled = this.slide.enabled
            this.entityData.position = this.slide.position
            this.entityData.title = this.slide.title
            this.entityData.url = this.slide.url
            this.entityData.txtZone1 = this.slide.txtZone1
            this.entityData.txtZone2 = this.slide.txtZone2
            this.entityData.txtZone3 = this.slide.txtZone3

            if (this.slide.mainImage) {
              this.entityData.mainImage = this.slide.mainImage
            }

            if (this.slide.mobileImage) {
              this.entityData.mobileImage = this.slide.mobileImage
            }

            this.valid = true
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putSlide () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      this.entityData.locale = this.$route.params.locale

      Vue.prototype.$http
        .put(config.apiUrl + '/api/slides/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.slide = response.data

            this.snackbar = true
            this.successMessage = 'Slide mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    }
  }
}
</script>

